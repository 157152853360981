import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const ClientsSection = () => {
    return (
        <section id = 'clients-section' style = {sectionStyle}>
            <div className = 'contanier-fluid'>

                <h2 className = 'text-center' style = {headingStyle}>Some clients I have <br></br> worked with</h2>
                <div className = 'rowtest' style = {testStyle}>
                    <div className = ' d-flex client-col  text-center' style = {colStyle}>
                    <img className = 'img-fluid client-logo' src = '/logos/repak-logo.png' style = {logoStyle}></img>
                    </div>
                    <div className = ' d-flex client-col  text-center' style = {colStyle}>
                    <img className = 'img-fluid client-logo' src = '/logos/landrover-logo.png' style = {logoStyle}></img>
                    </div>
                    <div className = ' d-flex client-col  text-center' style = {colStyle}>
                    <img className = 'img-fluid client-logo' src = '/logos/lucozade-logo.png' style = {logoStyle}></img>
                    </div>
                    <div className = ' d-flex client-col  text-center' style = {colStyle}>
                    <img className = 'img-fluid client-logo' src = '/logos/jaguar-logo.png' style = {logoStyle}></img>
                    </div>
                    <div className = ' d-flex client-col  text-center' style = {colStyle}>
                    <img className = 'img-fluid client-logo' src = '/logos/esb-logo.png' style = {logoStyle}></img>
                    </div>
                    <div className = ' d-flex client-col  text-center' style = {colStyle}>
                    <img className = 'img-fluid client-logo' src = '/logos/brennans-logo.png' style = {logoStyle}></img>
                    </div>
                    <div className = ' d-flex client-col  text-center d-none d-md-block' style = {colStyle}>
                    <img className = 'img-fluid client-logo' src = '/logos/tech-logo.jpeg' style = {logoStyle}></img>
                    </div>
                    
                </div>
                <div className = 'rowtest' style = {testStyle}>
                    <div className = ' d-flex client-col   text-center' style = {colStyle}>
                    <img className = 'img-fluid client-logo' src = '/logos/fulfil-logo.png' style = {logoStyle}></img>
                    </div>
                    <div className = ' d-flex client-col   text-center' style = {colStyle}>
                    <img className = 'img-fluid client-logo' src = '/logos/jigsaw-logo.jpg' style = {logoStyle}></img>
                    </div>
                    <div className = ' d-flex client-col   text-center' style = {colStyle}>
                    <img className = 'img-fluid client-logo' src = '/logos/qualtrics-logo.png' style = {logoStyle}></img>
                    </div>
                    <div className = ' d-flex client-col   text-center' style = {colStyle}>
                    <img className = 'img-fluid client-logo' src = '/logos/ucd-logo.png' style = {logoStyle}></img>
                    </div>
                    <div className = ' d-flex client-col   text-center' style = {colStyle}>
                    <img className = 'img-fluid client-logo' src = '/logos/ribena-logo.svg' style = {logoStyle}></img>
                    </div>
                    <div className = ' d-flex client-col   text-center' style = {colStyle}>
                    <img className = 'img-fluid client-logo' src = '/logos/whiteclaw-logo.png' style = {logoStyle}></img>
                    </div>
                    <div className = ' d-flex client-col   text-center d-none d-md-block' style = {colStyle}>
                    <img className = 'img-fluid client-logo' src = '/logos/atim-logo.png' style = {logoStyle}></img>
                    </div>
                    
                </div>
            </div>    
         </section>
    )
}

const sectionStyle = {
    paddingLeft:'100px',
    paddingRight:'100px',
    maxWidth:'1300px',
    margin:'0 auto'
}

const logoStyle = {
    maxWidth:'120px',
    maxHeight:'120px'
}

const row2Style = {
    marginTop : '50px'
}

const headingStyle = {
    marginBottom:'60px'
}

const colStyle = {
    justifyContent:'center',
    alignItems:'center',
    marginBottom:'10px',
    margin:'10px 10px'
}

const testStyle = {
    display:'flex',
    flexWrap :'wrap',
    justifyContent:'space-around',
    marginBottom:'40px',
    alignItems:'center'

}

export default ClientsSection
