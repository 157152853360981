import React,{useEffect} from 'react'
import { StaticImage } from "gatsby-plugin-image"
import AOS from 'aos'

const AboutSection = () => {

    useEffect(() => {
        AOS.init({
            duration:600,
            once:false
        })
    },[])

    return (
        <section id = 'about-section' style = {sectionStyle}>
            <div className = 'container-fluid p-0 '>
                <h1 className = 'text-center'>About Me</h1>

                <div className ='timeline-wrapper' style = {timelineWrapperStyle}>
                    <div className = 'timeline-line' style = {lineStyle}></div>

                    <div style = {rowStyle} className = 'timeline-row'>
                    
                        <div style = {row1} className = 'about-1 profile-image' id = 'about-profile-image'   data-aos = 'fade-up'>
                           
                            <img src = '/annmarie-new.jpeg' className = 'profile-image' style = {profileImageStyle}></img>
                        </div>

                        
                        <div style = {row2} className = 'about-2'>
                        
                            <div style = {{display:'none'}}>
                            <div style = {{width:'100%'}}>
                                     <div style = {circleLeftStyle} className = 'timeline-circle' data-aos = 'zoom'> </div>
                                 </div>
                                <h3 className = 'timeline-heading' style = {headingStyle} data-aos = 'fade-up'>The Burning of Knockcroghery</h3>
                                <h4 className = 'timeline-subheading' style = {subHeadingStyle} data-aos = 'fade-up'>Author | Illustrator | Published</h4>
                                <div className = 'timeline-year' style = {yearStyle} data-aos='fade-up'>2021</div>
                                <p className = 'timeline-description jost ' style = {descriptionStyle} data-aos='fade-up'>I wrote, illustrated and published a historical fiction about the burning of
Knockcroghery village. I marketed this book for school-going children with great
success. Due to the historical accuracy of the book it has been placed in the National
Library, Dublin. The book has also been implemented in many primary school across
Roscommon. A live radio interview on the Ryan Tubridy show followed a successful
advertising campaign. </p>
                            </div>

                            <div style = {{marginTop:'100px'}}>

                            <div style = {{width:'100%', }}>
                                     <div style = {circleLeftStyle} className = 'timeline-circle'data-aos = 'zoom'></div>
                                 </div>
                                <h3 className = 'timeline-heading' style = {headingStyle} data-aos='fade-up'>Freelance Designer <br/>& illustrator</h3>
                                <div className = 'timeline-year' style = {yearStyle} data-aos = 'fade-up'>2021 - present</div>
                                <p className = 'timeline-description jost' style = {descriptionStyle} data-aos = 'fade-up'>I continue to collaborate on various projects with a number of diverse clients.
Projects include illustrating, creating social media content, animations, power point
design, brand identity design and motion graphics as well as general graphic design.</p>
                            </div>
                        </div>
                    </div>

                    <div style = {{display:'flex', width:'100%', paddingTop:'300px'}} className = 'timeline-row'>
                        <div style = {row1} className = 'about-1 google-1' >

                             <div style = {{marginTop:'30px'}}>
                             <div style = {{width:'100%', }}>
                                     <div style = {circleLeftStyle} className = 'timeline-circle'data-aos = 'zoom'></div>
                                 </div>
                                <h3 className = 'timeline-heading' style = {headingStyle} data-aos = 'fade-up'>Google UX/UI Certificate</h3>
                                <h4 className = 'timeline-subheading' style = {subHeadingStyle} data-aos = 'fade-up'>Coursera Online</h4>
                                <div className = 'timeline-year' style = {yearStyle} data-aos = 'fade-up'>2020</div>
                                <p className = 'timeline-description jost' style = {descriptionStyle} data-aos = 'fade-up'>As part of my Google UX/UI certificate I became proficient in developing user personas, stories and journey maps. Based off research, I conducted usability studies, site maps, user flows, wireframes and prototypes. This course encouraged me to think about user pain points and designing for people with disabilities.</p>
                            </div>
                        </div>

                        <div style = {row2} className = 'about-2 google-2'>
                            <div className = 'text-center'>
                                <img src = '/google-animation.gif' style = {googleImageStyle} className = 'about-google-image'></img>
                                
                            </div>
                        </div>
                    </div>

                    <div style = {{display:'flex', width:'100%', paddingTop:'300px'}} className = 'timeline-row'>
                    
                        <div style = {row1} className = 'about-1' >
                        <div className = 'text-center'>
                            <img src = '/newspaper-gif.gif' className = 'about-mindshare-image' style = {googleImageStyle} />
                                
                            </div>
                        </div>

                        <div style = {row2} className = 'about-2'>
                        <div style = {{marginTop:'30px'}}>
                        <div style = {{width:'100%', }}>
                                     <div style = {circleLeftStyle} className = 'timeline-circle'data-aos = 'zoom'></div>
                                 </div>
                                <h3 className = 'timeline-heading' style = {headingStyle} data-aos = 'fade-up' >Mindshare | GroupM</h3>
                                <h4 className = 'timeline-subheading' style = {subHeadingStyle} data-aos = 'fade-up'>Digital Advertising Agency</h4>
                                <div className = 'timeline-year' style = {yearStyle} data-aos = 'fade-up'>2020 - 1 year.</div>
                                <p className = 'timeline-description jost' style = {descriptionStyle}data-aos = 'fade-up' >I began my career as a digital designer at Mindshare/ GroupM, where I gained
invaluable experience animating advertisements for well-established brands. I

developed social media marketing skills, built client relationships and fostered
organisation skills in the work place. </p>
                            </div>
                            
                        </div>
                    </div>

                    <div style = {{display:'flex', width:'100%', paddingTop:'300px', position:'relative'}} className = 'timeline-row'>
                        <img src = '/hero-background.png' style = {backgroundCircleStyle1} classsName = 'hero-background'></img>
                    
                        <div style = {row1} className = 'about-1 blackpug-1'>
                             <div style = {{marginTop:'30px'}}>
                             <div style = {{width:'100%', }}>
                                     <div style = {circleLeftStyle} className = 'timeline-circle'data-aos = 'zoom'></div>
                                 </div>
                                <h3 className = 'timeline-heading' style = {headingStyle} data-aos = 'fade-up'>Design Associates</h3>
                                <h4 className = 'timeline-subheading' style = {subHeadingStyle} data-aos = 'fade-up'>Summer Work</h4>
                                <div className = 'timeline-year' style = {yearStyle} data-aos = 'fade-up'>2019 - 2 months</div>
                                <p className = 'timeline-description jost' style = {descriptionStyle} data-aos = 'fade-up'>After my first internship I was eager to further develop my skills in a working environment. I joined Design Assoicates for the summer months before returning to final year University. Here I practised general design as well as word-press. </p>
                            </div>

                             <div style = {{marginTop:'100px'}}>
                             <div style = {{width:'100%', height:'100%'}}>
                                     <div style = {circleLeftStyle} className = 'timeline-circle'data-aos = 'zoom'></div>
                                 </div>
                                <h3 className = 'timeline-heading' style = {headingStyle} data-aos = 'fade-up'>Black Pug Studio</h3>
                                <h4 className = 'timeline-subheading' style = {subHeadingStyle} data-aos = 'fade-up'>University Internship</h4>
                                <div className = 'timeline-year' style = {yearStyle} data-aos = 'fade-up' >2019 - 4 weeks</div>
                                <p className = 'timeline-description jost' style = {descriptionStyle} data-aos = 'fade-up'> As part of my student work placement I joined the team at Black Pug Studio, where I  learned skills in web design and practised adobe XD.  </p>
                            </div>
                        </div>

                        <div style = {row2} className = 'about-2 blackpug-2'>
                            <div className = 'text-center'>
                            <img src = '/coffee-animation.gif' style = {blackpugImageStyle} className = 'about-blackpug-image'></img>

                                
                            </div>
                        </div>
                    </div>

                    <div style = {{paddingTop:'300px', display:'flex', position:'relative'}} className = 'timeline-row'>
                    <div style = {{position:'absolute',width:'100%', }}>
                        <img src = '/hero-background.png' style = {backgroundCircleStyle2} className = 'hero-background'></img>
                                 </div>
                        <div style = {row1} className = 'about-1 text-center'>
                            <div>
                            <img src = '/graphic-illustration.gif' style = {googleImageStyle} className = 'about-google-image'></img>

                        
                            </div>
                        </div>
                        <div style = {row2} className = 'about-2'>
                            <div style = {{width:'100%', }}>
                                     <div style = {circleLeftStyle} className = 'timeline-circle'data-aos = 'zoom'></div>
                                 </div>
                        

                            <div >
                                <h3 className = 'timeline-heading' style = {headingStyle} data-aos = 'fade-up'>Graphic Design <br/>& Communications</h3>
                                <h4 className = 'timeline-subheading' style = {subHeadingStyle} data-aos = 'fade-up'>Limerick School of Art and Design</h4>
                                <div className = 'timeline-year' style = {yearStyle} data-aos = 'fade-up'>2016-2020</div>
                                <p className = 'timeline-description jost' style = {descriptionStyle} data-aos = 'fade-up'>I attended University at LSAD, Limerick (LIT) with an honours Bachelores degree in Graphic Design & Communications. Here I zoned in on my interests for illustration and packaging.  </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const sectionStyle = {
    paddingTop:'100px',
    paddingBottom:'200px',
    paddingLeft:'150px',
    paddingRight:'150px',
    minHeight:'100vh',
    overflow:'hidden'
}

const timelineWrapperStyle = {
    width:'100%',
    paddingTop:'150px',
    position:'relative'
   
}

const lineStyle = {
    width:'1px',
    backgroundColor:'#707070',
    opacity:'0.5',
    left:'50%',
    transform:'translateX(-50%)',
    height:'85.5%',
    position:'absolute'
}

const rowStyle = {
    display:'flex',
    width:'100%'
}

const row1 = {
    width:"470px"
}

const row2 = {
    marginLeft:'auto',
    width:'470px'
}

const contentWrapperStyle = {
    width:'400px',
    height:'300px'
}

const headingStyle = {
    color:'#464748',
    fontSize:'32px',
    fontWeight:'normal'
}

const subHeadingStyle = {
    fontSize:'24px',
    fontWeight:'normal',
    opacity:'0.7'
}

const yearStyle = {
    fontSize:'18px',
    color:'#fd0d1b'
}

const descriptionStyle = {
    color:'#000000',
    fontSize:'18px',
    marginTop:'20px'
}

const profileImageStyle = {
    width:'450x',
    height:'600px'
}

const googleImageStyle = {
    height:'420px',
    margin:'0 auto'
}

const blackpugImageStyle = {
    height:"550px",
    zIndex:'999'
}

const circleLeftStyle = {
    width:'18px',
    height:'18px',
    backgroundColor:"#FD0D1B",
    position:'absolute',
    left:"50%",
    transform:'translateX(-50%)',
    borderRadius:'50%'
}

const backgroundCircleStyle1 = {
    position:'absolute',
    width:'650px',
    height:'650px',
    right:'-450px',
    top:'250px',
    zIndex:'-9'
}
const backgroundCircleStyle2 = {
    position:'absolute',
    width:'650px',
    height:'650px',
    left:'-450px',
    top:'-200px'
}



export default AboutSection
