import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroSection from "../components/HeroSection"
import ContactSection from "../components/ContactSection"
import AboutSection from "../components/AboutSection"
import ClientsSection from "../components/ClientsSection"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <HeroSection />
    <ContactSection />
    <AboutSection/>
    <ClientsSection />
  </Layout>
)

export default IndexPage
