import React, {useEffect} from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import Aos from 'aos'


const HeroSection = () =>  {

    
    return (
        <> 
        <section id = "hero-section" style = {heroStyle}>
            <div id = 'hero-text-wrapper' style = {textWrapperStyle}>
                <div id = 'hero-heading-text'>
                <span style = {headingStyle} className = 'hero-heading-1'>Hey there,</span> <br/>
                <span style = {headingstyle2} className = 'hero-heading-2'>I'm Annmarie.</span>
                </div>

                <div id = 'hero-subtext' style = {subtextWrapperStyle}>
                    <h3 style = {subtextHeadingStyle} className = 'hero-sub-heading'>Designer & Illustrator</h3>
                    <p style = {subTextStyle} className = 'hero-subtext'>I am an enthusiastic and energetic young creative, that likes to make extremely unique and creative designs. My area of interests are: Illustration, Web Design, and Motion Graphics.</p>
                </div>

                
                <div style = {btnWrapperStyle} className = 'hero-btn-wrapper'>
                    
                    <Link to = '/portfolio' className = 'btn btn-primary hero-btn text-dark' style = {heroBtnStyle}> 
                    <img src = '/hero-btn-image.png' style = {btnImageStyle} className = 'hero-btn-image'></img>
                    <span style = {btnTextStyle} className = 'hero-btn-text jost'>View Portfolio</span></Link>
                </div>
                

                

            </div>



            <div id = 'hero-image-container' style = {{overflow:'hiden'}}>
            <img  src = '/hero-background.png' style = {backgroundImageStyle} className = 'hero-background'  loading = 'eager'></img>
            <img src = '/hero-new.gif' style = {heroImageStyle} className = 'hero-illustration' loading = 'eager'></img>
            </div>



        </section>
        </>
    )
}

const heroStyle = {
    minHeight:'calc(100vh - 130px)',
    overflow:'hidden',
    position:'relative'
    
}

const textWrapperStyle = {
    paddingTop:'13vh',
    maxWidth:'1400px',
    margin:'0 auto',
    paddingLeft:"100px"
}

const headingStyle = {
    fontSize:'65px',
    lineHeight:'80px',
    letterSpacing:'1px',
    color:'#464748'
}

const headingstyle2 = {
    fontSize:'65px',
    lineHeight:'80px',
    marginLeft:'30px',
    letterSpacing:'1px',
    color:'#464748'
}

const subtextWrapperStyle = {
    marginTop:'15px',
    marginLeft:'30px'
}
const subtextHeadingStyle = {
    fontSize:'30px',
    fontWeight:'normal',
    color:'#ff0012',
    letterSpacing:'1px'
}

const subTextStyle = {
    fontSize:'20px',
    width:'470px',
    color:'#474849',
    lineHeight:'25px'

}

const backgroundImageStyle = {
    position:'absolute',
    zIndex:'-1',
    right:'-130px',
    bottom:'-200px',
    width:'90vh',
    height:'90vh'
}

const heroImageStyle = {
position:'absolute',
right:'-50px',
zIndex:'-1',
overflow:'hidden',
width:'60vw',
height:'80vh'
}

const heroBtnStyle =  {
    height:'73px',
    width:'200px',
    backgroundColor: 'white ',
    borderColor: '#ff0012 ',
    borderRadius: '16px',
    display:'flex',
    justifyContent:'space-around',
    marginLeft:'auto',
    marginTop:'45px'

  }

  const btnTextStyle = {
    marginLeft:'10px',
    alignSelf:'center',
    fontSize:'15px'
  }

  const btnWrapperStyle = {
      width:'460px'
  }

  const linkStyle = {
      textDecoration:'none',
      color:'inherit'
  }

  const btnImageStyle = {
      width:'71px',
      height:'71px'
  }

export default HeroSection
