import React, {useEffect} from 'react'
import { StaticImage } from "gatsby-plugin-image"
import AOS from 'aos'


const ContactSection = () => {

    useEffect(() => {
        AOS.init({
            duration:600,
            once:false
        })
    },[])

    return (
        <section id = 'contact-section' style = {contactStyle}>
            <div className = 'container-fluid contact-container'>
                <div className="row p-0">
                    <div className="col-lg-6 contact-image-col"  data-aos = 'fade-up'>
                        <div id = 'contact-image-wrapper' style = {imageWrapperStyle}>
                            <img src = '/hero-background.png' style = {backgroundImageStyle} className = 'contact-background'></img>
                                

                            <img src = '/contact-gif.gif' style = {illustrationStyle} className = 'contact-illustration'></img>
                              
                        </div>
                    </div>    
                    <div className="col-lg-6 contact-text-col"  data-aos = 'fade-up'>
                    <div id = 'contact-text-wrapper' style = {textWrapperStyle}>
                        <h2 style = {headingStyle} className = 'contact-heading'>Let's Get in touch</h2>
                        <p style = {emailStyle} className = 'contact-email jost'><a href = 'mailto:annmarie97murray@gmail.com' style  = {{textDecoration:'none'}}>Annmarie97murray@gmail.com </a></p>
                        <div className = 'social-wrapper d-flex'>
                            <span style = {iconStyle} className = 'contact-icon'> <a href = 'https://www.instagram.com/annmaries_designs/?hl=en' target = '_blank'><i class="bi bi-instagram"></i></a></span>
                            <span style = {iconStyle} className = 'contact-icon'> <a href = 'https://www.linkedin.com/in/ann-marie-murray-5a58971b1/' target = '_blank'><i class="bi bi-linkedin"></i></a></span>
                        </div>
                    </div>
                    </div>    
                </div>    
             </div>

        </section>
    )
}

const contactStyle = {
    height:'100vh',
    paddingTop:'200px',
    paddingLeft:'100px',
    paddingRight:'100px',
    paddingBottom:'100px'
}

const backgroundImageStyle = {
    width:'434px',
    height:'434px',
    position:'absolute',
    zIndex:'-1',
    
}

const illustrationStyle = {
    height:'438px',
    margin:'30px auto'
}

const imageWrapperStyle = {
    display:'flex',
    justifyContent:'center',
    overflow:'visible'
}


const textWrapperStyle = {
    display:"flex",
    flexDirection:'column',
    justifyContent:'center',
    width:'400px',
    margin:'0 auto',
    height:'100%',
    alignItems:'center'
}

const iconStyle = {
    fontSize:'38px',
    marginRight:'10px'
}

const headingStyle = {
    fontSize:'34px',
    color:'#fd0d1b',
    fontWeight:'normal'
}
const emailStyle = {
    fontSize:'22px',
    color:'#434343'
}

export default ContactSection
